<template>
  <div ref="draggableContainer" id="draggable-container" @mousedown="dragMouseDown" @touchstart="dragMouseDown">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableDiv",
  data: function () {
    return {
      parentElmt: undefined,
      page: {
        x: 0,
        y: 0,
      },
      dragoffset: {
        x: 0,
        y: 0,
      },
      limit: {
        x: 0,
        y: 0,
      },
      offset: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    dragMouseDown: function (e) {
      e.preventDefault();

      this.parentElmt = this.$refs.draggableContainer.parentElement;

      this.page.x = e.pageX || e.clientX || e.changedTouches[0].pageX;
      this.page.y = e.pageY || e.clientY || e.changedTouches[0].pageY;

      this.dragoffset.x = this.page.x - this.$refs.draggableContainer.offsetLeft;
      this.dragoffset.y = this.page.y - this.$refs.draggableContainer.offsetTop;

      this.$refs.draggableContainer.ontouchmove = this.elementDrag;
      this.$refs.draggableContainer.ontouchend = this.closeDragElement;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (e) {
      e.preventDefault();

      this.page.x = e.pageX || e.clientX || e.changedTouches[0].pageX;
      //|| (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft);

      this.page.y = e.pageY || e.clientY || e.changedTouches[0].pageY;
      //|| (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);

      this.limit.x = this.parentElmt.clientWidth;
      this.limit.y = this.parentElmt.clientHeight - 40;

      if (this.page.x - this.dragoffset.x < 0) {
        this.offsetX = 0;
      } else if (this.page.x - this.dragoffset.x + this.$refs.draggableContainer.clientWidth > this.limit.x) {
        this.offsetX = this.limit.x - this.$refs.draggableContainer.clientWidth;
      } else {
        this.offsetX = this.page.x - this.dragoffset.x;
      }

      if (this.page.y - this.dragoffset.y < 0) {
        this.offsetY = 0;
      } else if (this.page.y - this.dragoffset.y + this.$refs.draggableContainer.clientHeight > this.limit.y) {
        this.offsetY = this.limit.y - this.$refs.draggableContainer.clientHeight;
      } else {
        this.offsetY = this.page.y - this.dragoffset.y;
      }

      this.$refs.draggableContainer.style.left = this.offsetX + "px";
      this.$refs.draggableContainer.style.top = this.offsetY + "px";
    },
    closeDragElement() {
      this.$refs.draggableContainer.ontouchend = null;
      document.ontouchend = null;
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
</script>

<style></style>
