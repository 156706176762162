<template>
  <div id="wrapper" class="d-flex align-items-center py-3">
    <div class="container">
      <div class="row d-flex align-items-stretch justify-content-center">
        <div class="col-12 p-0">
          <div class="d-flex align-items-center justify-content-center flex-wrap mt-1 h-100">
            <div class="text-center">
              <img v-if="logoCliente" id="logoCliente" :src="logoCliente" class="d-block" />
              <a class="btn btn-light btn-outline-success px-2 py-0 mt-3" @click="share">
                <i class="fas fa-link"></i>&nbsp;
                <span class="link-url">{{ link }}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4 p-0">
          <div class="container">
            <div class="row d-flex align-items-stretch align-items-xs-center">
              <div class="col-lg-6 col-sm-12 d-flex algin-items-center justify-content-xs-center justify-content-sm-center justify-content-lg-end">
                <div id="box-card" class="shadow">
                  <div id="loading" v-if="loading">
                    <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <figure ref="card">
                    <img id="moldura" :src="activeCover" />
                    <DraggableDiv ref="nome" id="nome" class="text-border">{{ nome }}</DraggableDiv>
                    <vueCropper ref="cropper" :img="foto" :full="true" :original="false" :infoTrue="true" mode="cover"></vueCropper>
                  </figure>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 d-flex align-items-stretch justify-content-sm-center justify-content-lg-start">
                <div class="col-right px-4 py-3">
                  <div class="container p-0">
                    <div class="row">
                      <div class="col-12">
                        <h4 class="text-success text-center text-uppercase mb-2">Deixe seu apoio e faça parte dessa onda</h4>
                        <p class="text-center">Digite seu nome e crie seu cartão.</p>
                        <div class="mt-2">
                          <div class="form-group mb-1">
                            <input
                              type="text"
                              class="form-control text-uppercase"
                              :disabled="step === 'download'"
                              id="name"
                              v-model="nome"
                              maxlength="16"
                              placeholder="Digite seu nome"
                            />
                          </div>
                          <div class="form-group">
                            <div class="d-grid gap-2">
                              <label for="photo" class="custom-file-upload btn btn-success text-light text-uppercase">
                                <i class="fas fa-camera"></i> Escolha sua foto
                              </label>
                            </div>
                            <input id="photo" type="file" @change="changePhoto" :disabled="step === 'download'" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <hr class="border-bottom border-success mb-2" />
                        <div v-if="molding.length > 1 && step != 'download'" class="row d-flex align-items-center justify-content-start mb-3">
                          <div class="col"><b>Escolha capa</b></div>
                          <div class="col">
                            <div v-for="(image, i) in molding" :key="image" class="thumb border border-dark mx-1 shadow-sm transiton" @click="changeCover(i)">
                              <img :src="image" />
                            </div>
                          </div>
                        </div>
                        <div v-if="steps">
                          <div v-if="step === 'upload'">
                            <small class="text-dark">Clique e arraste na área ao lado, ou use os botões abiaxo para ajustar sua foto e nome.</small>
                            <div class="container p-0">
                              <div class="boxActions row d-flex align-items-center mt-2">
                                <div class="col-lg-10 col-xs-12">
                                  <div class="d-flex align-items-center justify-content-center bg-light rounded shadow">
                                    <button
                                      type="button"
                                      class="btn btn-light px-2 py-1"
                                      @click="rotateLeft"
                                      title="Girar para a esquerda"
                                      alt="Girar para a esquerda"
                                    >
                                      <i class="fas fa-undo"></i>
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-light px-2 py-1"
                                      @click="rotateRight"
                                      title="Girar para a direita"
                                      alt="Girar para a direita"
                                    >
                                      <i class="fas fa-redo"></i>
                                    </button>
                                    &nbsp;|&nbsp;
                                    <button
                                      type="button"
                                      class="btn btn-light px-2 py-1"
                                      @click="textLeft"
                                      title="Alinhar texto à esquerda"
                                      alt="Alinhar texto à esquerda"
                                    >
                                      <i class="fas fa-align-left"></i>
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-light px-2 py-1"
                                      @click="textRight"
                                      title="Alinhar texto à direita"
                                      alt="Alinhar texto à direita"
                                    >
                                      <i class="fas fa-align-right"></i>
                                    </button>
                                    <input
                                      type="color"
                                      class="form-control form-control-color px-2 py-1"
                                      @input="textColor"
                                      title="Alterar cor do texto"
                                      alt="Alterar cor do texto"
                                    />
                                    &nbsp;|&nbsp;
                                    <button type="button" class="btn btn-light px-2 py-1" @click="resetPositions" title="Redefinir" alt="Redefinir">
                                      <i class="fas fa-sync"></i>
                                    </button>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-xs-12 d-grid gap-2">
                                  <button class="btn btn-success float-end" @click="changeStep('download')" title="Avançar" alt="Avançar">
                                    <i class="fas fa-angle-double-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="step === 'download'">
                            <p class="text-dark">Baixe, mude seu avatar e compartilhe nas redes sociais.</p>
                            <div class="container p-0">
                              <div class="row">
                                <div class="col-2">
                                  <button class="btn btn-success" @click="changeStep('upload')" title="Voltar" alt="Voltar">
                                    <i class="fas fa-angle-double-left"></i>
                                  </button>
                                </div>
                                <div class="col-10">
                                  <div class="d-grid gap-2">
                                    <button :class="{ disabled: loading }" class="btn btn-primary text-uppercase" @click="downloadCard">
                                      <span v-if="loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Carregando...
                                      </span>
                                      <span v-else><i class="fas fa-download"></i> fazer download do card </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Modal Share -->
        <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
          <button class="btn btn light modal__close" @click="showModal = false">x</button>
          <span class="modal__title text-center lh-1 text-uppercase fs-6">Compartilhe com seus amigos.</span>
          <div class="modal__content mt-4">
            <div class="text-center">
              <button class="btn btn-primary mx-1" @click="openWindow('facebook')"><i class="bi bi-facebook"></i> Facebook</button>
              <button class="btn btn-info text-light mx-1" @click="openWindow('twitter')"><i class="bi bi-twitter"></i> Twitter</button>
            </div>
            <p class="text-center mt-4">Ou copie o link da campanha</p>
            <p class="d-flex justify-content-center" @click="copyText">
              <span ref="cardLink" id="cardLink" class="bg-light rounded px-3 py-1 text-secondary btn link-url"> <i class="fas fa-copy"></i> {{ link }} </span>
            </p>
          </div>
        </vue-final-modal>
      </div>
    </div>
  </div>
</template>

<script>
//import { ref } from "vue";
import html2canvas from "html2canvas";
import { useToast } from "vue-toastification";
import { VueCropper } from "vue-cropper";
import DraggableDiv from "./DraggableDiv.vue";
import { VueFinalModal } from "vue-final-modal";

import axios from "axios";
//import VueAxios from "vue-axios";

import "bootstrap-icons/font/bootstrap-icons.css";
import "vue-cropper/dist/index.css";

export default {
  name: "home",
  components: {
    VueCropper,
    DraggableDiv,
    VueFinalModal,
  },
  emits: ["openModal"],
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      nome: "",
      foto: "",
      molding: {},
      activeCover: "",
      loading: false,
      steps: false,
      step: "",
      showModal: false,
      logoCliente: false,
      link: "https://cards.elejase-app.com.br/",
    };
  },
  async beforeMount() {
    this.loading = true;
    const hash = location.hash;
    this.link += hash;

    let token = location.hash.replace("#", "");

    const form = new FormData();
    form.append("token", token);

    await axios.post("https://elejase-app.com.br/temaImages.php", form).then((response) => {
      this.logoCliente = response.data.logoCliente || "/img/logo_elejase.png";
      this.molding = response.data.images;
      this.activeCover = this.molding[0];
      this.loading = false;
    });
  },
  methods: {
    async changePhoto(event) {
      this.loading = true;
      const file = event.target.files[0];

      if (event.target.files.length > 0) {
        if (file.type != "image/jpeg" && file.type != "image/png" && file.type != "image/gif") {
          this.toast.error("Apenas JPG, PNG ou GIF são permitidos.", {
            timeout: 5000,
            position: "top-center",
          });

          this.loading = false;
          this.steps = false;
          this.step = "";

          return false;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (e) => {
          this.loading = false;
          this.foto = e.target.result;
          this.steps = true;
          this.step = "upload";
        };
      } else {
        this.loading = false;
      }
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    textLeft() {
      this.$refs.nome.$el.classList.remove("text-end");
    },
    textRight() {
      this.$refs.nome.$el.classList.add("text-end");
    },
    textColor(elm) {
      this.$refs.nome.$el.style.color = elm.target.value;
    },
    resetPositions() {
      this.$refs.nome.$el.classList.remove("text-end");
      this.$refs.nome.$el.style.top = "0px";
      this.$refs.nome.$el.style.left = "0px";
      this.$refs.cropper.refresh();
    },
    changeCover(n) {
      this.activeCover = this.molding[n];
    },
    changeStep(step) {
      this.step = step;
    },
    async downloadCard() {
      this.loading = true;
      const el = document.getElementById("box-card");

      const options = {
        type: "dataURL",
      };

      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "CARD.jpg");
      link.setAttribute("href", printCanvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream"));
      link.click();
      this.loading = false;
    },
    share() {
      this.showModal = true;
    },
    openWindow(target) {
      var url = "";

      switch (target) {
        case "facebook":
          url = "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftwb.nz%2Fmovimentouniaopelaadvocacia&title=undefined&description=&quote=&hashtag=";
          break;
        case "twitter":
          url = "https://twitter.com/intent/tweet?text=DanieChris&url=https%3A%2F%2Ftwb.nz%2Fmovimentouniaopelaadvocacia";
          break;
      }

      window.open(url, "", "width=600,height=500,left=" + (window.outerWidth - 600) / 2 + ", top=" + (window.outerHeight - 500) / 2);
    },
    copyText() {
      var copyText = this.$refs.cardLink;
      this.$copyText(copyText.innerText).then(() => {
        this.toast.success("Texto copiado!", {
          timeout: 2000,
        });
      });
    },
  },
};
</script>

<style scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.modal-content) {
  width: 400px;
  min-width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  padding: 0px 0px;
  position: absolute;
  top: 5px;
  right: 10px;
}

.dark-mode div :deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
